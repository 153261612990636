import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import EventInfo from "../components/EventInfo";
import { Helmet } from "react-helmet";
import EventImage from "../components/EventImg";
import SimplePurchase from '../simple_purchase/components/SimplePurchase';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, InputAdornment, Typography, Fab} from '@material-ui/core';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { makeStyles } from '@material-ui/styles';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



function EventPage() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const eventId = searchParams.get("eventId");

  const [event, setEvent] = useState({});

  useEffect(() => {
    async function fetchEvent() {
      try {
        const response = await fetch(`https://api.tickazo.com/eventos/${eventId}`);
        const data = await response.json();
        setEvent(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchEvent();
  }, [eventId]);

  if (loading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    }}>
        <CircularProgress color="primary"/>
    </div>;
  }
  
  return (
    <>
      <Helmet>
        <title>Tickazo | {event.nombre}</title>
        <meta name="description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar."/>
        <meta property="og:title" content={`Tickazo | ${event.nombre}`} />
        <meta property="og:url" content={`https://tickazo.com/events?eventId=${eventId}`} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar."/>
        <meta property="og:image" content={event.img_poster} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Tickazo | ${event.nombre}`} />
        <meta name="twitter:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar." />
        <meta name="twitter:image" content="https://tickazo.com/images/tickazo-logo.png" />
        <meta name="twitter:image:alt" content="Tickazo logo" />
      </Helmet>
      <div style={{ margin: '16px', display: 'flex', justifyContent: 'center' }}>
        <Grid container alignItems="center" className="event-page-container">
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <EventImage img_header={event.img_header} nombre={event.nombre} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <EventInfo event={event} />
          </Grid>
        </Grid>
      </div>
      <BuyForm eventId={eventId} />*/
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  whiteUnderline: {
    '&:before': {
      borderBottomColor: 'white',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'white',
    },
    '&:after': {
      borderBottomColor: 'white',
    },
  },
  fab: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
  },
}));

function BuyForm({ eventId }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    celular: '',
    dateOfBirth: '',
    dni: '',
    sexo: '',
    cantidad: '',
    ticket_id: 27,
    event_id: eventId,
    code_area: '54',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if name celular or dni, only allow numbers
    if (name === 'celular' || name === 'dni') {
      console.log(e.target.value.replace(/[^0-9]/g, ""));
      setFormData({
        ...formData,
        [name]: value.replace(/[^0-9]/g, ""),
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);

    //post request to endpoint
    fetch(`https://api.tickazo.com/ecommerce/simple-purchase`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        evento: eventId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        alert('Tenés asegudara tu entrada! Ahora te redirigimos para que puedas pagarla');
        window.location.href = data['payment_link'];
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error al realizar la compra');
      });
  };

  const subTotal = formData.cantidad * 15000;
  const serviceCharge = subTotal * 0.06;
  const total = subTotal + serviceCharge;


  return (
    <>
      <div style={{ margin: '16px', display: 'flex', justifyContent: 'center' }}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" style={{ color: 'white', marginBottom: '16px', textAlign: 'center' }}>
            Realizar compra
          </Typography>
          <Typography variant="subtitle1" style={{ color: 'white', marginBottom: '16px' }}>
            Las entradas quedan guardadas en el sistema con tu DNI. Para entrar al evento, solamente tenes que presentarte en la puerta con el DNI y seguridad las procesará.
          </Typography>
          <TextField
            label="Nombre"
            name="nombre"
            type="text"
            required
            value={formData.nombre}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              style: { color: 'white' },
              classes: {
                underline: classes.whiteUnderline,
              },
              inputProps: { maxLength: 64 },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
          />
          <TextField
            label="Apellido"
            name="apellido"
            type="text"
            required
            value={formData.apellido}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              style: { color: 'white' },
              classes: {
                underline: classes.whiteUnderline,
              },
              inputProps: { maxLength: 64 },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
          />
          <TextField
            label="Celular"
            name="celular"
            type='text'
            required
            value={formData.celular}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="Codigo de area sin el 0 y sin el 15 (Ej: 3513985814)"
            InputProps={{
              startAdornment: <InputAdornment position="start" style={{ color: 'white' }}>+54</InputAdornment>,
              style: { color: 'white' },
              classes: {
                underline: classes.whiteUnderline,
              },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            FormHelperTextProps={{
              style: { color: 'white' },
            }}
          />
          <TextField
            label="DNI"
            name="dni"
            type="text"
            required
            value={formData.dni}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              style: { color: 'white' },
              classes: {
                underline: classes.whiteUnderline,
              },
              inputProps: { maxLength: 8 },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
          />
          <TextField
            label="Fecha de nacimiento"
            name="dateOfBirth"
            type="date"
            required
            value={formData.dateOfBirth}
            onChange={handleChange}
            fullWidth
            margin="normal"
            // placeholder="dd/mm/yyyy"
            InputProps={{
              style: { color: 'white' },
              classes: {
                underline: classes.whiteUnderline,
              },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel style={{ color: 'white' }}>Sexo (como aparece en el documento)</InputLabel>
            <Select
              name="sexo"
              value={formData.sexo}
              onChange={handleChange}
              style={{ color: 'white' }}
              classes={{
                underline: classes.whiteUnderline,
              }}
            >
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Femenino</MenuItem>
              <MenuItem value="X">Otro</MenuItem>
            </Select>
          </FormControl>
          <div style={{ margin: '16px 0', color: 'white' }}>
            <p>Entrada General $15000</p>
            <TextField
              label="Cantidad"
              name="cantidad"
              type="number"
              required
              value={formData.cantidad}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                style: { color: 'white' },
                classes: {
                  underline: classes.whiteUnderline,
                },
                inputProps: { min: 1, max: 10 },
              }}
              InputLabelProps={{
                style: { color: 'white' },
              }}
            />
          </div>
          <div style={{ margin: '16px 0', color: 'white' }}>

            <Typography variant="h6">Subtotal: ${subTotal}</Typography>
            <Typography variant="h6">Costo de servicio: ${serviceCharge}</Typography>
            <Typography variant="h6">Total: ${total}</Typography>
          </div>
          <Button type="submit" variant="contained" color="primary">
            Confirmar compra
          </Button>
        </form>
      </div>
      <Fab
        color="primary"
        aria-label="help"
        className={classes.fab}
        href="https://api.whatsapp.com/send?phone=5493513985814&text=Hola%20Tickazo!%20Me%20comunico%20porque..." // Reemplaza con tu número de WhatsApp
        target="_blank"
      >
        <WhatsAppIcon />
      </Fab>
    </>
  );
}

export default EventPage;
